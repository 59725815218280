@import url('https://fonts.googleapis.com/css2?family=Anton+SC&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Anton+SC&family=Kalnia+Glaze:wght@100..700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Space+Grotesk:wght@300..700&display=swap');
* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
  }

@tailwind base;
@tailwind components;
@tailwind utilities;